@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* 您的其他自定义样式 */
body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300; /* 使用较轻的字重作为默认 */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* 为英文文本设置特定的字体样式 */
.english-text {
  font-family: 'Poppins', sans-serif;
  font-weight: 300; /* 保持轻量感 */
  letter-spacing: 0.03em; /* 稍微增加字母间距，增强现代感 */
  line-height: 1.6; /* 增加行高，提高可读性 */
}

/* 为标题添加更强的视觉效果 */
h1, h2, h3, h4, h5, h6 {
  font-weight: 500; /* 使用中等字重增强对比 */
  letter-spacing: -0.02em; /* 稍微收紧字母间距，增强设计感 */
}

/* 为按钮和重要元素添加更强的视觉效果 */
.btn, .nav-item {
  font-weight: 400; /* 使用常规字重 */
  text-transform: uppercase; /* 大写字母增强现代感 */
  letter-spacing: 0.05em; /* 增加字母间距，提高辨识度 */
}

/* 添加或修改以下样式 */
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  /* 使用 100dvh 可以解决一些移动浏览器的视口高度问题 */
  min-height: 100dvh;
}

#root {
  width: 100%;
}

* {
  box-sizing: inherit;
}